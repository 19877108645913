@import "../../_globalColor";

.dev-icons {
  padding-left: 0;
  list-style: none;
  font-size: 3rem;
  margin-bottom: 0px;
  margin-top: 0px;
  text-align: center;

  .software-skill-inline {
    display: inline-block;
    margin-right: 20px;
    margin-bottom: 20px;

    .icon-style {
      color: $subTitle;

      svg {
        fill: $subTitle; // Set initial fill color
      }

      &:hover {
        color: $skillsColor;

        svg {
          fill: $skillsColor; // Update fill color on hover
        }
      }
    }

    p {
      color: $subTitle;
      font-size: 10px;
    }

    .icon-style:hover ~ p {
      color: $skillsColor;
    }
  }
}
